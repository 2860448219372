       .container-pad-vertical{
            padding: 10px !important;
            width: calc(370px) !important;
            letter-spacing: normal !important;
            border:solid 1px black
        }
      .border--bottom td{
          border-bottom: 1px solid #6A26B1 !important;
          padding: 5px !important;
          letter-spacing: normal !important;
      }
      .main-table{
          margin-bottom: 20px !important;
          width: 100% !important;
          letter-spacing: normal !important;
      }
      .main-table th {
          font-size: 14px !important;
          text-align: left !important;
          border-top: 1px solid #6A26B1 !important;
          letter-spacing: normal !important;
          padding: 5px !important;
      }
      .bottom-text{
          text-align: center !important;
          letter-spacing: normal !important;
      }
      .bottom-logo{
          text-align: right !important;
          letter-spacing: normal !important;
      }
      .extr-a td{
          border-bottom: 5px solid #6A26B1 !important;
          letter-spacing: normal !important;
      }
      .nutrimb10{
          margin-bottom: 10px !important;
          letter-spacing: normal !important;
      }
      .btn{
          float: right !important;
          border: 1px solid #e6e6e6 !important;
          border-radius: 5px !important;
          background-color: #e6e6e6 !important;
          width: 52px !important;
          height: 24px !important;
          font-size: 13px !important;
          font-weight: 500 !important;
          letter-spacing: normal !important;
          position: relative !important;
          top: -6px !important;
          color: #423b4a !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
      }
      .serving-qty{
          border-bottom: 11px solid #000 !important;
          letter-spacing: normal !important;
          vertical-align: middle !important;
           letter-spacing: normal !important;
         
      }
  
      .spanny{
          font-size: 13px !important;
          line-height: 20px !important;
          letter-spacing: normal !important;
          display: inline-block !important;
          color: #000 !important;
      }
      .serving-val{
          display: flex !important;
          justify-content: space-between !important;
          margin-bottom: 3px !important;
          letter-spacing: normal !important;
      }
      .serving-val strong{
          font-size: 12px !important;
          letter-spacing: normal !important;
      }
      strong{
          font-size: 14px !important;
          line-height: 20px !important;
          color: #000 !important;
          letter-spacing: normal !important;
      }
      .nutri-block{
          border-bottom: 4px solid #000 !important;
          letter-spacing: normal !important;
          
      }
      .nutri-value{
          display: flex !important;
          justify-content: space-between !important;
          margin: 3px 0px !important;
          align-items: center !important;
          letter-spacing: normal !important;
      }
      .nutri-value strong{
          font-size: 28px !important;
          line-height: 30px !important;
          font-weight: 700 !important;
          color: #000 !important;
          letter-spacing: normal !important;
      }
      .nutri-value .value{
          font-size: 40px !important;letter-spacing: normal !important;
          font-weight: 700 !important;
      }
      .nutri-value .heading span{
          font-size: 14px !important;
          line-height: 14px !important;
          color: #000 !important;letter-spacing: normal !important;
          display: block !important;
          font-weight: 700 !important;
      }
      .nutri-list-item{
          display: flex !important;
          border-bottom: 1px solid #000 !important;letter-spacing: normal !important;
          width:100% !important;
      }
   
      .list-sub-item{
          display: flex !important;
          border-bottom: 1px solid #666 !important;
          letter-spacing: normal !important;
      }
      .nutri-fact-table{
          border-bottom: 11px solid #000 !important;
          letter-spacing: normal !important;
      }
      .vitamin-block{
          border-bottom: 4px solid #000 !important;
          letter-spacing: normal !important;
      }
      .vita-item{
          display: flex !important;
          justify-content: space-between !important;
          border-bottom: 1px solid #666 !important;letter-spacing: normal !important;
      }
      .nutrinote p{
            white-space: pre-line !important;

          margin: 0px !important;
          padding: 5px 0px 5px 15px !important;
          position: relative !important;letter-spacing: normal !important;
      }
      .nutrinote p sup{
          position: absolute !important;
          top: 4px !important;
          left: 6px !important;letter-spacing: normal !important;
      }
      .w--40{
          width:40% !important;
          letter-spacing: normal !important;
      }
      .w-30{
          width:30% !important;
          letter-spacing: normal !important;
      }
      .w--20{
          width:20% !important;
          letter-spacing: normal !important;
      }
      .text-right{
          text-align:right !important;
          letter-spacing: normal !important;
      }
      .pl-10{
        letter-spacing: normal !important;
          padding-left: 10px
      }

      .p-facts-style{
       margin:0 !important; font-weight: 900 !important;font-size:30px !important;border-bottom:1px solid black !important; letter-spacing: normal !important;
      }

