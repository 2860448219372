* {
  box-sizing: border-box ;
}

.circle {
  font-family: Verdana ;
  overflow: hidden ;
  border: 1px solid ;
  position: relative ;
  height: 150px ;
  width: 168px ;
  border-radius: 40% 40% 20% 20% ;
}

.circle-fill {

  position: absolute ;
  top: 0 ;
  left: 0 ;
  height: 100% ;
  width: 100% ;
   border-radius: 40% 40% 20% 20% ;

  color: black ;
}

.circle-fill-text-top {
  position: absolute ;
  top: 14% ;
  /*left: 18% ;
  transform: translate(-50%, -50%) ;*/
  color: black ;
  width: 100% ;
  text-align: center ;
   white-space: pre-wrap ;
        letter-spacing: normal  ;


}

.circle-fill-text-middle {
  position: absolute ;
  top: 55% ;
  left: 90% ;
  transform: translate(-50%, -50%) ;
  color: black ;
   font-size: 12px ;
   width: 100% ;

}

.circle-fill-text-bottom {
  position: absolute ;
  top: 60% ;
  left: 50% ;
  transform: translate(-50%, -50%) ;
  color: black ;
}

.circle-divider {
  position: absolute ;
  top: 65% ;
  color: black ;
  font-weight: bold ;
}

.circle-overlay {
  top: 0 ;
  left: 0 ;
  height: 100% ;
  width: 100% ;
  background: white ;
  animation: circle-overlay-anim 1s forwards ;
  transform-origin: 0 0 ;
}

@keyframes circle-overlay-anim {
  100% {
    transform: scaleY(0) ;
  }
}
@page { size: 620px 200px }