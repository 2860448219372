@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header-icon {
    @apply w-6 h-6 cursor-pointer;
  }
  .nav-icon {
    @apply w-6 h-6 cursor-pointer;
  }
}


@layer base {

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
  vertical-align: initial; 
}

}
