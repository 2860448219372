
        .container-pad-horizontal{
            padding: 10px ;
            width: calc(1020px) ;
          letter-spacing: normal ;

        }
        .mb5{ 
            margin-bottom: 5px ;
          letter-spacing: normal ;

        }
        .mb10{
            margin-bottom: 10px ;
          letter-spacing: normal ;

        }
        .mb20{
            margin-bottom: 20px ;
          letter-spacing: normal ;

        }
        .mb30{
          letter-spacing: normal ;

            margin-bottom: 30px ;
        }
        .border-btm{
          letter-spacing: normal ;

            border-bottom: 1px solid black ;
        }
        .pad-5{
            padding: 5px ;
          letter-spacing: normal ;

        }
        .margin-left{
            margin-left: 15px ;
          letter-spacing: normal ;

        }
        .flex-row{
            display: flex ;
            align-items: center ;
            justify-content: space-between ;
          letter-spacing: normal ;

        }
        .flex-column{
            display: flex ;
            flex-direction: column ;
          letter-spacing: normal ;

        }
        .hair-line-seprator{
            margin-bottom: 2.66px ;
            padding-top: 2.66px ;
            border-bottom: 1px solid ;
          letter-spacing: normal ;

            width: 100% ;
        }
        .seprator{
            padding-top: 2.66px ;
            border-bottom: 4px solid ;
          letter-spacing: normal ;

            width: 100% ;
        }
        .text-lg{
            font-size: 15px ;
          letter-spacing: normal ;

        }
        .text-xlg{
            font-size: 24px ;
          letter-spacing: normal ;

        }
        .text-light{
            font-size: 12px ;
            line-height: 14px ;
            font-weight: 200 ;
          letter-spacing: normal ;

        }
        .text-bold{
            font-weight: 700 ;
          letter-spacing: normal ;

        }
        .text-sm{
          letter-spacing: normal ;

            font-size: 11px ;
        }
        .label-container{
            display: flex ;
            justify-content: space-between ;
          letter-spacing: normal ;


            border: 1px solid ;
            padding: 7px ;
            color: #000 ;
            background-color: #fff ;
            -webkit-print-color-adjust: exact ;
            line-height: 12px ;
            width: calc(100% - 14px) ;
        }
        .label-container .label-cols{
            display: flex ;
          letter-spacing: normal ;

            flex-direction: column ;
            white-space: pre-line ;
        }
        .label-container .label-cols:first-child{
            width: calc(20% - 20px) ;
            margin-right: 20px ;
          letter-spacing: normal ;

            justify-content: space-around ;
        }
        .label-container .label-cols:nth-child(2){
            width: calc(70% - 10px) ;
          letter-spacing: normal ;

            margin-right: 10px ;
            flex-direction: row ;
        }
        .label-container .label-cols:last-child{
            width: 10% ;
          letter-spacing: normal ;

        }
        .label-container .label-cols h3{
            font-size: 24px ;
            line-height: 100% ;
            font-weight: 900 ;
            padding-top: 5px ;
          letter-spacing: normal ;

            border-bottom: 1px solid ;
            width: 100% ;
        }
        .label-container .label-cols p{
            font-size: 10px ;
            line-height: 16px ;
          letter-spacing: normal ;

        }
        .nutrition-block{
            width: calc(50% - 10px) ;
            margin-right: 10px ;
          letter-spacing: normal ;


            height: 100% ;
            display: flex ;
            flex-direction: column ;
            justify-content: space-between ;
        }
        .row-item{
            display: flex ;
            align-items: center ;
            margin-bottom: 3px ;
            padding-bottom: 3px ;
          letter-spacing: normal ;

        }
        .row-item ul{
            display: flex ;
            justify-content: space-between ;
            width: 100% ;
          letter-spacing: normal ;

        }
        .w-40{
          letter-spacing: normal ;
            width:40% ;
        }
        .w-30{
          letter-spacing: normal ;
            width:30% ;
        }
        .w-20{
          letter-spacing: normal ;
            width:20% ;
        }
        .text-right{
          letter-spacing: normal ;
            text-align:right ;
        }
        .label-head{
          letter-spacing: normal ;
            padding-left:5px ;
        }
        .pl-10{
          letter-spacing: normal ;
            padding-left:10px
        }